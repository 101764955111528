import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Flex,
  Grid,
  Button,
  Link,
  Text,
  Heading,
  Image,
  Card,
  Label,
  Input,
  Select,
  Textarea,
  Radio,
  Checkbox,
  Slider,
  Progress,
  Donut,
  Spinner,
  Avatar,
  Badge,
  Close,
  Alert,
  Container,
  NavLink,
  Message,
  IconButton,
  MenuButton,
} from 'theme-ui';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';

const Section = ({ children, title }) => (
  <Box>
    <Text as="h3">{title}</Text>
    <Box m="auto" mt={2} p={20} bg="lightGrey">
      {children}
    </Box>
  </Box>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const ThemePage = () => (
  <Layout>
    <SEO title="Theme" />
    <Section title="Box">
      <Box bg="primary">
        This is a box
      </Box>
    </Section>
    <Section title="Flex">
      <Flex>
        <Box p={2} bg="primary" sx={{ flex: '1 0 auto' }}>
          Flex
        </Box>
        <Box p={2} bg="muted">
          Box
        </Box>
      </Flex>
    </Section>
    <Section title="Grid">
      <Grid width={[128, null, 192]}>
        <Box bg="primary">Box</Box>
        <Box bg="muted">Box</Box>
        <Box bg="primary">Box</Box>
        <Box bg="muted">Box</Box>
      </Grid>
    </Section>
    <Section title="Button">
      <Flex>
        <Button mr={2}>Beep</Button>
        <Button variant="secondary">Boop</Button>
      </Flex>
    </Section>
    <Section title="Text">
      <Text
        sx={{
          fontSize: 4,
          fontWeight: 'bold',
        }}
      >
        Hello
      </Text>
      <Text variant="caps">Hello</Text>
    </Section>
    <Section title="Header">
      <Heading>Hello</Heading>
      <Heading as="h3">Subhead</Heading>
    </Section>
    <Section title="Link">
      <Grid>
        <Link href="#!">Hello</Link>
        <Link href="#!" variant="primary">Primary</Link>
      </Grid>
    </Section>
    <Section title="Image">
      <Image
        variant="bordered"
        sx={{ width: 300 }}
        src="https://images.unsplash.com/photo-1520222984843-df35ebc0f24d?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjF9"
      />
    </Section>
    <Section title="Card">
      <Card
        sx={{
          maxWidth: 256,
          bg: 'white',
        }}
      >
        <Image src="https://images.unsplash.com/photo-1520222984843-df35ebc0f24d?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjF9" />
        <Text>
          Card
        </Text>
      </Card>
    </Section>
    <Section title="Form">
      <Box
        as="form"
        onSubmit={(e) => e.preventDefault()}
      >
        <Label htmlFor="username">Username</Label>
        <Input
          name="username"
          mb={3}
        />
        <Label htmlFor="password">Password</Label>
        <Input
          type="password"
          name="password"
          mb={3}
        />
        <Box>
          <Label mb={3}>
            <Checkbox />
            Remember me
          </Label>
        </Box>
        <Label htmlFor="sound">Sound</Label>
        <Select name="sound" mb={3}>
          <option>Beep</option>
          <option>Boop</option>
          <option>Blip</option>
        </Select>
        <Label htmlFor="comment">Comment</Label>
        <Textarea
          name="comment"
          rows="6"
          mb={3}
        />
        <Flex mb={3}>
          <Label>
            <Radio name="letter" />
            {' '}
            Alpha
          </Label>
          <Label>
            <Radio name="letter" />
            {' '}
            Bravo
          </Label>
          <Label>
            <Radio name="letter" />
            {' '}
            Charlie
          </Label>
        </Flex>
        <Label>
          Slider
        </Label>
        <Slider mb={3} />
        <Button>
          Submit
        </Button>
      </Box>
    </Section>
    <Section title="Progress">
      <Progress max={1} value={1 / 2}>
        50%
      </Progress>
    </Section>
    <Section title="Donut">
      <Donut value={1 / 2} />
    </Section>
    <Section title="Spinner">
      <Spinner />
    </Section>
    <Section title="Avatar">
      <Avatar
        src="https://contrast.now.sh/fff/000?text=UI&size=96&fontSize=1.5&baseline=1"
      />
    </Section>
    <Section titl="Badge">
      <Heading>
        Components
        <Badge variant="accent">New</Badge>
        <Badge variant="outline" ml={1}>Cool</Badge>
      </Heading>
    </Section>
    <Section title="Close">
      <Close />
    </Section>
    <Section title="Alert">
      <Grid>
        <Alert>
          Beep boop, this is an alert!
          <Close ml="auto" mr={-2} />
        </Alert>
        <Alert variant="secondary" mb={2}>Secondary</Alert>
        <Alert variant="accent" mb={2}>Accent</Alert>
        <Alert variant="highlight" mb={2}>Highlight</Alert>
      </Grid>
    </Section>
    <Section title="Container">
      <Container
        p={4}
        bg="muted"
      >
        Centered container
      </Container>
    </Section>
    <Section title="NavBar">
      <Flex as="nav">
        <NavLink href="#!" p={2}>
          Home
        </NavLink>
        <NavLink href="#!" p={2}>
          Blog
        </NavLink>
        <NavLink href="#!" p={2}>
          About
        </NavLink>
      </Flex>
    </Section>
    <Section title="Message">
      <Message>
        This is just a message for someone to read
      </Message>
    </Section>
    <Section title="IconButton">
      <IconButton
        aria-label="Toggle dark mode"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="currentcolor"
        >
          <circle
            r={11}
            cx={12}
            cy={12}
            fill="none"
            stroke="currentcolor"
            strokeWidth={2}
          />
        </svg>
      </IconButton>
    </Section>
    <Section title="MenuButton">
      <MenuButton
        aria-label="Toggle Menu"
      />
    </Section>
  </Layout>
);

export default ThemePage;
